<template>
    <div class="price_box">
        <div class="title">
            <h3>火键产品套餐</h3>
            <p>根据您的团队规模与业务需求，选择合适的套餐</p>
        </div>
        <div class="price_group">
            <div class="price_list">
                <div class="price_hd">
                    <div class="lang_en">Professional</div>
                    <div>
                        <span class="lang_zh">专业版</span>
                    </div>

                    <img
                        src="https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/index/list_bg.png"
                        alt=""
                    />
                </div>
                <div class="open_box">
                    <div class="open_row">
                        <div class="open_item">
                            <i class="iconfont icon-lujing3"> </i> 视频合成数
                        </div>
                        <div class="open_item">100条</div>
                    </div>
                    <div class="open_row">
                        <div class="open_item">
                            <i class="iconfont icon-a-lujing1"> </i> 视频合成数
                        </div>
                        <div class="open_item">100条</div>
                    </div>
                    <div class="open_row">
                        <div class="open_item">
                            <i class="iconfont icon-lujing3"> </i> 视频合成数
                        </div>
                        <div class="open_item">100条</div>
                    </div>
                    <div class="open_row">
                        <div class="open_item">
                            <i class="iconfont icon-a-lujing1"> </i> 视频合成数
                        </div>
                        <div class="open_item">100条</div>
                    </div>
                    <div class="open_row">
                        <div class="open_item">
                            <i class="iconfont icon-lujing3"> </i> 视频合成数
                        </div>
                        <div class="open_item">100条</div>
                    </div>
                    <div class="open_row">
                        <div class="open_item">
                            <i class="iconfont icon-a-lujing1"> </i> 视频合成数
                        </div>
                        <div class="open_item">100条</div>
                    </div>
                </div>
                <el-button type="primary" round size="small"
                    >咨询报价</el-button
                >
            </div>
            <div class="price_list">
                <div class="price_hd">
                    <div class="lang_en">Professional</div>
                    <div>
                        <span class="lang_zh">专业版</span>
                    </div>

                    <img
                        src="https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/index/list_bg.png"
                        alt=""
                    />
                </div>
                <div class="open_box">
                    <div class="open_row">
                        <div class="open_item">
                            <i class="iconfont icon-lujing3"> </i> 视频合成数
                        </div>
                        <div class="open_item">100条</div>
                    </div>
                    <div class="open_row">
                        <div class="open_item">
                            <i class="iconfont icon-a-lujing1"> </i> 视频合成数
                        </div>
                        <div class="open_item">100条</div>
                    </div>
                    <div class="open_row">
                        <div class="open_item">
                            <i class="iconfont icon-lujing3"> </i> 视频合成数
                        </div>
                        <div class="open_item">100条</div>
                    </div>
                    <div class="open_row">
                        <div class="open_item">
                            <i class="iconfont icon-a-lujing1"> </i> 视频合成数
                        </div>
                        <div class="open_item">100条</div>
                    </div>
                    <div class="open_row">
                        <div class="open_item">
                            <i class="iconfont icon-lujing3"> </i> 视频合成数
                        </div>
                        <div class="open_item">100条</div>
                    </div>
                    <div class="open_row">
                        <div class="open_item">
                            <i class="iconfont icon-a-lujing1"> </i> 视频合成数
                        </div>
                        <div class="open_item">100条</div>
                    </div>
                </div>
                <el-button type="primary" round size="small"
                    >咨询报价</el-button
                >
            </div>
            <div class="price_list">
                <div class="price_hd">
                    <div class="lang_en">Professional</div>
                    <div>
                        <span class="lang_zh">专业版</span>
                    </div>

                    <img
                        src="https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/index/list_bg.png"
                        alt=""
                    />
                </div>
                <div class="open_box">
                    <div class="open_row">
                        <div class="open_item">
                            <i class="iconfont icon-lujing3"> </i> 视频合成数
                        </div>
                        <div class="open_item">100条</div>
                    </div>
                    <div class="open_row">
                        <div class="open_item">
                            <i class="iconfont icon-a-lujing1"> </i> 视频合成数
                        </div>
                        <div class="open_item">100条</div>
                    </div>
                    <div class="open_row">
                        <div class="open_item">
                            <i class="iconfont icon-lujing3"> </i> 视频合成数
                        </div>
                        <div class="open_item">100条</div>
                    </div>
                    <div class="open_row">
                        <div class="open_item">
                            <i class="iconfont icon-a-lujing1"> </i> 视频合成数
                        </div>
                        <div class="open_item">100条</div>
                    </div>
                    <div class="open_row">
                        <div class="open_item">
                            <i class="iconfont icon-lujing3"> </i> 视频合成数
                        </div>
                        <div class="open_item">100条</div>
                    </div>
                    <div class="open_row">
                        <div class="open_item">
                            <i class="iconfont icon-a-lujing1"> </i> 视频合成数
                        </div>
                        <div class="open_item">100条</div>
                    </div>
                </div>
                <el-button type="primary" round size="small"
                    >咨询报价</el-button
                >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PriceContent",

    data() {
        return {};
    },

    mounted() {},

    methods: {},
};
</script>

<style lang="less" scoped>
.price_box {
    min-height: 100vh;
    background-image: url(https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/index/%E4%BB%B7%E6%A0%BC%E8%83%8C%E6%99%AF.png);
    background-size: 100% 100%;
    padding: 100px 0;
    box-sizing: border-box;
}
.title {
    text-align: center;
    color: #fff;
    h3 {
        font-size: 34px;
        margin-bottom: 10px;
    }
    p {
        font-size: 16px;
    }
}
.price_group {
    display: flex;
    justify-content: center;
    margin-top: 80px;
}
.price_list {
    width: 310px;
    background-color: #fff;
    text-align: center;
    box-shadow: 0px 7px 9px -5px rgba(56, 69, 255, 0.0784),
        0px 14px 21px 2px rgba(56, 69, 255, 0.0392),
        0px 5px 26px 5px rgba(56, 69, 255, 0.05);
    border-radius: 10px;
    padding: 28px 0;
    & + .price_list {
        margin-left: 30px;
    }
    .lang_en {
        font-size: 16px;
        color: #3845ff;
    }
    .lang_zh {
        padding: 20px 30px;
        background-color: #3845ff;
        color: #fff;
        border-radius: 14px;
        display: inline-block;
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 20px;
    }
}
.open_box {
    padding: 14px 30px;
    color: #1d2129;
    .iconfont {
        font-size: 12px;
        margin-right: 10px;
    }
}
.open_row {
    display: flex;
    justify-content: space-between;
    line-height: 28px;
}
.icon-lujing3 {
    color: #37e2ab;
}
.icon-a-lujing1 {
    color: #4e5969;
}
</style>
