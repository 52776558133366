<template>
    <div>
        <topBar backgroundColor="#fff" />
        <!-- <banner /> -->
        <price_content />
        <foo />
    </div>
</template>

<script>
import topBar from "@/components/index/topbar.vue";
import price_content from "@/components/index/price_content.vue";
import foo from "@/components/index/foo.vue";
export default {
    name: "price",
    components: {
        topBar,
        price_content,
        foo,
    },
    data() {
        return {};
    },

    mounted() {},

    methods: {},
};
</script>

<style lang="less" scoped>
</style>